import React from 'react'
import styles from './styles.module.scss'

const Discount = ({value, top = '8px'}) => {
  return (
    <div className={styles.discountWrapper} style={{top: top}}>
      {value}% OFF
    </div>
  )
}

export default Discount
