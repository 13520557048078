import { mdiClose, mdiMagnify } from '@mdi/js';
import React, { useState } from 'react';
import NavItem from '../NavItem';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import SelectAutoSuggest from '../../Framework/SelectAutoSuggest';
import Button from '../../Button';
import styles from './styles.module.scss';

const SearchToolbar = ({ callSearch, handleToggleSearch, allProducts }) => {

    const [lastUpdated, setLastUpdated] = useState(null);

    const searchButtonStyle = {
        position: 'absolute',
        right: '58px',
        height: "2.5rem",
        width: "2.5rem",
        paddingLeft: "0rem",
        paddingRight: "0rem",
    };

    return (
        <nav className={styles.searchToolbar}>
            <SelectAutoSuggest placeholder="Search Products..." allSuggestions={allProducts} onSelectSuggestion={callSearch} inputClassName={styles.customInput} lastUpdated={lastUpdated} />
            <Button action={() => setLastUpdated(new Date())} customStyle={searchButtonStyle}>
                <Icon path={mdiMagnify} size={1} />
            </Button>
            <div className={styles.closeSearchToolbar}>
                <NavItem type="toggler" action={() => handleToggleSearch(false)} isActive={false} title="Cancel Search">
                    <Icon path={mdiClose}
                        size={1}
                    />
                </NavItem>
            </div>

        </nav>
    )
}

const mapStateToProps = state => {
    return {
        allProducts: state.productData.allProducts,
    };
};

export default connect(mapStateToProps)(SearchToolbar);
