import PropTypes from "prop-types";
import React, { useEffect } from 'react'
import { getShippingCountry } from "../../redux/actions/userAction";
import { multilanguage } from "redux-multilanguage";
import { setMerchant } from "../../redux/actions/storeAction";
import { getCategories } from "../../redux/actions/categoryAction";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { fetchAllProducts, fetchAllBrands } from "../../redux/actions/productActions";
import { getContent } from "../../redux/actions/contentAction";

const MainLayout = ({
    setMerchant,
    merchant,
    children,
    countries,
    defaultStore,
    currentLanguageCode,
    getShippingCountry,
    categories,
    getCategories,
    allProducts,
    fetchAllProducts,
    allBrands,
    fetchAllBrands,
    called,
    getContent
}) => {

    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    useEffect(() => {
        if (!countries.length) {
            getShippingCountry(currentLanguageCode);
        }

        if (!merchant) {
            setMerchant();
        }

        if (!categories.length) {
            getCategories();
        }

        if (!allProducts?.length) {
            fetchAllProducts(defaultStore, currentLanguageCode);
        }

        if (!allBrands?.length) {
            fetchAllBrands(defaultStore, currentLanguageCode);
        }

        if(!called) {
            getContent(defaultStore, currentLanguageCode);
        }

    }, [countries, getShippingCountry, merchant, setMerchant, allProducts, fetchAllProducts, allBrands, fetchAllBrands, called, getContent]);

    return (

        <div className={[styles.main, (mobileMenuOpen && styles.mobileMenuOpen)].join(" ")}>
            <AppHeader 
                mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={(data) => setMobileMenuOpen(data)}
            />
            <section className={styles.content}>
                {children}
            </section>
            <AppFooter />
        </div>

    )
}

MainLayout.propTypes = {
    children: PropTypes.any,
    headerContainerClass: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    headerTop: PropTypes.string
};

const mapStateToProps = state => {
    return {
        countries: state.userData.shipCountry,
        merchant: state.merchantData.merchant,
        categories: state.categories.categories,
        allProducts: state.productData.allProducts,
        allBrands: state.productData.allBrands,
        defaultStore: state.merchantData.defaultStore,
        currentLanguageCode: state.multilanguage.currentLanguageCode,
        called: state.content.called,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getShippingCountry: (value) => {
            dispatch(getShippingCountry(value));
        },
        setMerchant: () => {
            dispatch(setMerchant());
        },
        getCategories: () => { 
            dispatch(getCategories()) 
        },
        fetchAllProducts: (defaultStore, currentLanguageCode) => { 
            dispatch(fetchAllProducts(defaultStore, currentLanguageCode)) 
        },
        fetchAllBrands: (defaultStore, currentLanguageCode) => { 
            dispatch(fetchAllBrands(defaultStore, currentLanguageCode)) 
        },
        getContent: (defaultStore, currentLanguageCode) => { 
            dispatch(getContent(defaultStore, currentLanguageCode)) 
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(MainLayout));