import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

import Loader from "./components/loader/loader"
import Cookie from "./components/consent/Cookie"
import Cookies from 'universal-cookie';

import './App.scss';

import {
  setShopizerCartID
} from "./redux/actions/cartActions";
import SearchResults from "./pages/search";

// var sha512 = require('js-sha512').sha512;
// home pages
const OldHome = lazy(() => import("./pages/old-home/Home"));
const Home = lazy(() => import("./pages/home/index"));

// shop pages
const Category = lazy(() => import("./pages/category/Category"));

// product pages
const ProductDetail = lazy(() => import("./pages/product-details/ProductDetail"));

// other pages
const Contact = lazy(() => import("./pages/other/Contact"));
const Account = lazy(() => import("./pages/account"));
const NewLogin = lazy(() => import("./pages/login"));
const NewRegister = lazy(() => import("./pages/register"));
const NewForgotPassword = lazy(() => import("./pages/forgot-password"));
const NewUpdatePassword = lazy(() => import("./pages/reset-password"));
const ResetPassword = lazy(() => import("./pages/reset-password"));

const Cart = lazy(() => import("./pages/other/Cart"));
const RecentOrder = lazy(() => import("./pages/other/RecentOrder"));
const OrderDetails = lazy(() => import("./pages/other/OrderDetails"));
const NewCheckout = lazy(() => import("./pages/checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const OrderConfirm = lazy(() => import("./pages/other/OrderConfirm"));
const OrderFailure = lazy(() => import("./pages/other/OrderFailure"));
const PreOrderConfirm = lazy(() => import("./pages/preorder-confirm"));
const Content = lazy(() => import("./pages/content/Content"));
const SearchProduct = lazy(() => import("./pages/search-product/SearchProduct"));

const UnderConstruction = lazy(() => import("./pages/underConstruction"));
const AboutUs = lazy(() => import("./pages/about-us"));
const LegalPolicies = lazy(() => import("./pages/legal-policies"));



//export default function App = (props) => {
const App = (props) => {


  useEffect(() => {
    var cart_cookie = window._env_.APP_MERCHANT + '_shopizer_cart';
    const cookies = new Cookies();
    let cookie = cookies.get(cart_cookie);
    if (cookie) {
      //console.log('cookie !!! ' + cookie);
      props.dispatch(setShopizerCartID(cookie));
    }
    //console.log(window._env_);
    //document.documentElement.style.setProperty('--theme-color', window._env_.APP_THEME_COLOR)
    document.documentElement.style.setProperty('--theme-color', "rgba(35, 75, 151, 1)")
    //if(cookies[cart_cookie]) {
    //  console.log('cookie !!! ' + cookies[cart_cookie]);
    //  props.dispatch(setShopizerCartID(cookies[cart_cookie]));
    //}
    props.dispatch(
      loadLanguages({
        languages: { //from merchant supported languages
          en: require("./translations/english.json"),
          fr: require("./translations/french.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>

           <Loader></Loader>
           <Cookie></Cookie>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Routes>
                 {/* Home pages */}
                 <Route
                  exact
                  path="/"
                  element={<Home />}
                />

                <Route
                  exact
                  path="/old-home"
                  element={<OldHome />}
                />

                {/* Our Pages */}

                <Route
                  exact
                  path={"/about-us"}
                  element={<AboutUs />}
                />

                <Route
                  exact
                  path={"/legal-policies"}
                  element={<LegalPolicies />}
                />

                <Route
                  path="/preorder-confirm"
                  element={<PreOrderConfirm />}
                />

                <Route
                  path="/search"
                  element={<SearchResults />}
                />

                {/* Shop pages */}
                 <Route
                  path="/category/:id"
                  element={<Category />}
                /> 

                {/* Shop product pages */}
                 <Route
                  path="/product/:id"
                  element={<ProductDetail />}
                />
                <Route
                  path="/content/:id"
                  element={<Content />}
                />
                <Route
                  path="/search-product/:id"
                  element={<SearchProduct />}
                /> 

                {/* Other pages */}

                 <Route
                  path="/contact"
                  element={<Contact />}
                />
                <Route
                  path="/my-account"
                  element={<Account />}
                />
                <Route
                  path="/update-password"
                  element={<NewUpdatePassword forgot={false}/>}
                />
                <Route
                  path="/register"
                  element={<NewRegister />}
                />
                 <Route
                  path="/login"
                  element={<NewLogin />}
                />
                 <Route
                  path="/forgot-password"
                  element={<NewForgotPassword />}
                />
                <Route
                  path="/cart"
                  element={<Cart />}
                />
                <Route
                  path="/recent-order"
                  element={<RecentOrder />}
                />
                <Route
                  path="/order-details/:id"
                  element={<OrderDetails />}
                />
                <Route
                  path="/checkout"
                  element={<NewCheckout />}
                />

                <Route
                  path="/order-confirm"
                  element={<OrderConfirm />}
                />

                <Route
                  path="/order-pending"
                  element={<OrderConfirm />}
                />

                <Route
                  path="/order-failure"
                  element={<OrderFailure failure={true}/>}
                />

                <Route
                  path={"/not-found"}
                  element={<NotFound />}
                />

                <Route
                  path={"/under-construction"}
                  element={<UnderConstruction />}
                />

                <Route
                path="/customer/:code/reset/:id"
                element={<ResetPassword forgot={true}/>}
                />

                <Route exact element={<NotFound />} />
              </Routes>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
