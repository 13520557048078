import React from 'react'
import styles from './styles.module.scss'

import logo from '../../assets/images/lamansysLogo.png'

const AppFooterBrand = () => {
    return (
        <div className={styles.FooterBrand}>
            <img src={logo} alt="Lamansys Logo" />
        </div>


    )
}

export default AppFooterBrand
