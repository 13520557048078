import React from 'react'
import { Link } from "react-router-dom"
import styles from './styles.module.scss'


const BlogMenu = ({ blogs, onNavigate }) => {

    return (
        <div className={styles.menuContainer}>
            <ul className={styles.level1}>
                {
                    blogs.map((item, index) => {
                        return (
                            item.visible && item.description &&
                            <li key={index} >
                                <header>
                                    <Link to={"/content/" + item.description.friendlyUrl} onClick={onNavigate}>
                                        {item.description.name}
                                    </Link>
                                </header>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default BlogMenu
