import WebService from '../../util/webService';
import constant from '../../util/constant';
import { isCheckValueAndSetParams } from '../../util/helper';

export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";
export const SET_FRIENDLY_URL = "SET_FRIENDLY_URL";
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS";
export const FETCH_ALL_BRANDS = "FETCH_ALL_BRANDS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};
export const setProductID = (productID) => {
  return dispatch => {
    dispatch({
      type: SET_PRODUCT_ID,
      payload: productID
    });
  }
}
export const setFriendlyUrl = (friendlyUrl) => {
  return dispatch => {
    dispatch({
      type: SET_FRIENDLY_URL,
      payload: friendlyUrl
    });
  }
}
export const setCategoryID = (categoryID) => {

  return dispatch => {
    dispatch({
      type: SET_CATEGORY_ID,
      payload: categoryID
    });
  }
}
export const fetchAllProducts = (defaultStore, currentLanguageCode) => {
  return async dispatch => {
    const action = `${constant.ACTION.SEARCH_PRODUCTS}?${isCheckValueAndSetParams("&store=",defaultStore)}${isCheckValueAndSetParams("&lang=",currentLanguageCode)}`;
    const response = await WebService.get(action);
    if (response) {
        dispatch({
            type: FETCH_ALL_PRODUCTS,
            payload: response.products.filter(prod => prod.productShipeable && prod.available && prod.visible && prod.categories[0]?.visible).map(({description, image}) => ({description, image})),
        });
    }
  };
};
export const fetchAllBrands = (defaultStore, currentLanguageCode) => {
  return async dispatch => {
    const action = `${constant.ACTION.MANUFACTURERS}?${isCheckValueAndSetParams("&store=",defaultStore)}${isCheckValueAndSetParams("&lang=",currentLanguageCode)}`;
    const response = await WebService.get(action);
    if (response) {
        dispatch({
            type: FETCH_ALL_BRANDS,
            payload: response.manufacturers,
        });
    }
  };
};
