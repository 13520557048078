import React from "react";
import { useNavigate } from "react-router-dom"
import { mdiChevronLeft } from '@mdi/js'
import Icon from '@mdi/react'
import styles from './styles.module.scss';


const GoBack = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.goBack}>
            <Icon path={mdiChevronLeft} className={styles.goBackIcon} />
            <span  onClick={() => navigate(-1)}>Go Back</span>
        </div>
    )
}

export default GoBack;


