import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { Link, useNavigate } from "react-router-dom"
import { setUser } from "../../redux/actions/userAction";
import { setCategoryID } from "../../redux/actions/productActions";
import { deleteAllFromCart } from "../../redux/actions/cartActions";
import { setLocalData, getLocalData } from '../../util/helper';
import constant from '../../util/constant';
import WebService from '../../util/webService';
import Icon from '@mdi/react'
import { mdiAccountOutline, mdiMagnify } from '@mdi/js'

import HeaderBrand from '../AppHeaderBrand';
import NavItem from './NavItem';
import CartLink from './CartLink';
import MenuButton from './MenuButton';
import StoreMenu from './StoreMenu';
import UserMenu from './UserMenu';

import styles from './styles.module.scss'
import SearchToolbar from './SearchToolbar';
import BlogMenu from './BlogMenu';

const AppHeader = ({ cartCount, userData, setUser, deleteAllFromCart, categories, setCategoryID, mobileMenuOpen, setMobileMenuOpen, currentLanguageCode, defaultStore, contentData }) => {

    const dynamicNav = React.createRef();
    const userMenu = React.createRef();
    const [storeMenuOpen, setStoreMenuOpen] = useState(false);
    const [blogMenuOpen, setBlogMenuOpen] = useState(false);
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [storeMenuHeight, setStoreMenuHeight] = useState('auto');
    const [userDetails, setUserDetails] = useState(null);

    const navigate = useNavigate();

    //Get User Profile from API
    const getProfile = async () => {
        let action = constant.ACTION.AUTH + constant.ACTION.CUSTOMER + constant.ACTION.PROFILE;
        try {
            let response = await WebService.get(action);
            if (response) {
                setUserDetails(response)
            }
        }
        catch (error) {
            setUser('')
            setLocalData('token', '')
            navigate('/') // Why we need to redirect to home page?
        }
    }

    const logout = () => {
        setUser('')
        setLocalData('token', '')
        deleteAllFromCart()
        navigate(0)
    }

    const handleToggleMobileMenu = () => {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
            setStoreMenuOpen(false);
            setUserMenuOpen(false);
            setBlogMenuOpen(false);
        } else {
            setMobileMenuOpen(true);
            setUserMenuOpen(true);
        }
    };

    // Before redirect using Link, we need to set the category ID in the store
    const onClickCategory = (item) => {
        // As we navigate using menu, we need to close the menu before move to next page.
        onNavigate();
        setCategoryID(item.id)
    }

    // Before redirect using Link, we need to set the category ID in the store
    const onNavigate = () => {
        // As we navigate using menu, we need to close the menu before move to next page.
        setMobileMenuOpen(false);
        setStoreMenuOpen(false);
        setUserMenuOpen(false);
        setBlogMenuOpen(false);
    }

    const handleToggleStoreMenu = (isActive) => {
        setStoreMenuOpen(isActive);
        if (!mobileMenuOpen) {
            setUserMenuOpen(false);
        }
        setSearchOpen(false);
        setBlogMenuOpen(false);
    }

    const handleToggleBlogMenu = (isActive) => {
        setBlogMenuOpen(isActive);
        if (!mobileMenuOpen) {
            setUserMenuOpen(false);
        }
        setSearchOpen(false);
        setStoreMenuOpen(false);
    }

    const handleToggleUserMenu = (isActive) => {
        setUserMenuOpen(isActive);
        setStoreMenuOpen(false);
        setSearchOpen(false);
        setBlogMenuOpen(false);
    }

    const handleToggleSearch = (isActive) => {
        setUserMenuOpen(false);
        setStoreMenuOpen(false);
        setMobileMenuOpen(false);
        setBlogMenuOpen(false);
        setSearchOpen(isActive);

    }

    const closeAll = () => {
        setMobileMenuOpen(false);
        setStoreMenuOpen(false);
        setUserMenuOpen(false);
        setBlogMenuOpen(false);
    }

    const callSearch = (searchText) => {
        navigate(`/search?q=${searchText}`)
    }

    const getStoreMenuSize = () => {
        const userMenuHeight = userMenu?.current?.clientHeight || 0;
        const dynamicNavHeight = dynamicNav?.current?.clientHeight || 0;
        //console.log(userMenuHeight, dynamicNavHeight)
        setStoreMenuHeight(`calc(100vh - 5rem - 3rem - ${userMenuHeight}px - ${dynamicNavHeight}px)`);

    };

    useEffect(() => {
        if (getLocalData('thekey') === window._env_.APP_BASE_URL) {
            setLocalData('thekey', window._env_.APP_BASE_URL)
        } else {
            logout()
            setLocalData('thekey', window._env_.APP_BASE_URL)
        }
        if (userData) {
            getProfile()
        }
        //console.log("userMenu", userMenu)
    }, [])

    useEffect(() => {
        getStoreMenuSize();
    }, [mobileMenuOpen, userMenuOpen, storeMenuOpen]);

    // Update 'width' and 'height' when the window resizes
    useEffect(() => {
        window.addEventListener("resize", getStoreMenuSize);
    }, []);


    //This function was used to redirect to the first category page (by order)
    // const onClick = () => {
    //     const category = categories.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)[0];
    //     const { id, description } = category;
    //     setCategoryID(id);
    //     navigate(`/category/${description.friendlyUrl}`);
    // }

    return (
        <>
            <header className={[styles.header, (mobileMenuOpen ? styles.open : null)].join(" ")}>

                {/* HEADER */}
                <div className={styles.headerBrand}>
                    <HeaderBrand />
                </div>

                {/* DYNAMIC NAV */}
                {
                    !searchOpen &&
                    <nav className={[styles.dynamicNav, (!mobileMenuOpen ? styles.closed : null)].join(" ")} ref={dynamicNav} id="dynamicNav">
                        <ul>
                            <li>
                                <NavItem to="/" onClick={()=> onNavigate()} title="Go to Home">Home</NavItem>
                            </li>
                            <li>
                                <NavItem to="/about-us" onClick={()=> onNavigate()} title="About Us">About Us</NavItem>
                            </li>
                            <li>
                                <NavItem type="toggler" action={(data) => handleToggleStoreMenu(data)} isActive={storeMenuOpen} title="View our products" showCaret={true}><span className={styles.bold}>Store</span></NavItem>
                            </li>
                            {contentData.some(blog => blog.visible && blog.description) && 
                                <li>
                                    <NavItem type="toggler" action={(data) => handleToggleBlogMenu(data)} isActive={blogMenuOpen} title="Blogs" showCaret={true}><span className={styles.bold}>Blogs</span></NavItem>
                                </li>
                            }
                        </ul>
                    </nav>
                }

                {/* FIXED NAV */}
                {
                    !searchOpen &&
                    <nav className={styles.fixedNav}>
                        <ul>
                            <li>
                                <NavItem type="toggler" action={(data) => handleToggleSearch(data)} isActive={searchOpen} title="Search products">
                                    <Icon path={mdiMagnify}
                                        size={1}
                                    />
                                </NavItem>
                            </li>
                            <li className={styles.userAccountItem}>
                                {userDetails ?
                                    <NavItem type="toggler" action={(data) => handleToggleUserMenu(data)} isActive={userMenuOpen} showCaret={userDetails} title="Login or register">
                                        <Icon path={mdiAccountOutline}
                                            size={1}
                                        />
                                    </NavItem>
                                    :
                                    <NavItem to="/login" title="Login" >
                                        <Icon path={mdiAccountOutline} size={1} />
                                    </NavItem>
                                }
                            </li>
                            <li>
                                <CartLink cartCount={cartCount} />
                            </li>
                            <li className={[styles.menuButton].join(" ")}>
                                <MenuButton handler={handleToggleMobileMenu} active={mobileMenuOpen} />
                            </li>

                        </ul>
                    </nav>
                }

                {/* SEARCH NAV */}
                {
                    searchOpen &&
                    <nav className={styles.searchNav}>
                        <SearchToolbar callSearch={data => callSearch(data)} handleToggleSearch={data => handleToggleSearch(data)} />
                    </nav>
                }

                {/* STORE MENU */}
                <section className={[styles.megaMenu, styles.storeMenu, !storeMenuOpen && styles.closed].join(" ")}
                    style={{ maxHeight: mobileMenuOpen ? storeMenuHeight : "auto" }}
                >
                    <StoreMenu categories={categories} onClickCategory={onClickCategory}/>
                </section>

                {/* BLOGS MENU */}
                {contentData.some(blog => blog.visible && blog.description) && <section className={[styles.megaMenu, styles.storeMenu, !blogMenuOpen && styles.closed].join(" ")}
                    style={{ maxHeight: mobileMenuOpen ? storeMenuHeight : "auto" }}
                >
                    <BlogMenu blogs={contentData} onNavigate={onNavigate}/>
                </section>}

                {/* USER MENU */}
                <section className={[styles.megaMenu, styles.userMenu, !userMenuOpen && styles.closed].join(" ")} ref={userMenu} id="userMenu">
                    <UserMenu userDetails={userDetails} onNavigate={onNavigate}/>
                </section>
                <div className={[styles.layout, (mobileMenuOpen ? styles.open : null)].join(" ")}>

                    {/* <div className={[styles.divider, styles.divider1].join(" ")}></div> */}

                    {/* <div className={[styles.divider, styles.divider2].join(" ")}></div> */}

                </div>
            </header>
            <div className={[styles.headerOverlay, ((mobileMenuOpen || storeMenuOpen || userMenuOpen) && styles.open)].join(" ")} onClick={closeAll}></div>
        </>


    )
}


const mapStateToProps = state => ({
    categories: state.categories.categories,
    cartCount: state.cartData.cartCount,
    userData: state.userData.userData,
    currentLanguageCode: state.multilanguage.currentLanguageCode,
    defaultStore: state.merchantData.defaultStore,
    contentData: state.content.allContent,
});

const mapDispatchToProps = dispatch => ({
    setCategoryID: (id) => {
        dispatch(setCategoryID(id))
    },
    setUser: (data) => {
        dispatch(setUser(data));
    },
    deleteAllFromCart: () => {
        dispatch(deleteAllFromCart())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(AppHeader));