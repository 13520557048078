import constant from "../../util/constant";
import WebService from "../../util/webService";

export const SET_CONTENT_ID = "SET_CONTENT_ID";
export const GET_CONTENT = "GET_CONTENT";

export const setContent = (isValue) => {
    return dispatch => {
        dispatch({
            type: SET_CONTENT_ID,
            payload: isValue
        });
    };
};

export const getContent = (defaultStore, currentLanguageCode) => {
    return async dispatch => {
        const action = constant.ACTION.CONTENT + constant.ACTION.PAGES + '?page=0&count=200&store=' + defaultStore + '&lang=' + currentLanguageCode;
        const response = await WebService.get(action);
        if (response) {
            dispatch({
                type: GET_CONTENT,
                payload: response
            });
        }
    };
};
