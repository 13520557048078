import React from 'react'
import styles from './styles.module.scss';
import Button from '../Button';

const EmptyResults = ({action}) => {
    return (
        <section className={styles.emptyResults}>

            <header>
                <h1>No results found</h1>
                <p>We couldn't find what you searched for.</p>
                <p>Try searching again or browse our products.</p>
                <Button action={action}
                    variant="secondary" outline >
                    Browse Products
                </Button>
            </header>

        </section>
    )
}

export default EmptyResults
