import WebService from '../../util/webService';
import constant from '../../util/constant';

export const GET_CATEGORIES = "GET_CATEGORIES";

export const getCategories = () => {
    return async dispatch => {
        const action = constant.ACTION.CATEGORY + '?count=200&page=0&store=' + window._env_.APP_MERCHANT; // + defaultStore + '&lang=' + currentLanguageCode;
        const response = await WebService.get(action);
        if (response) {
            dispatch({
                type: GET_CATEGORIES,
                payload: response
            });
        }
    };
};
