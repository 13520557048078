import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from "react-router-dom"
import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'
import styles from './styles.module.scss'

const NavItem = ({ type, action, to, title, showCaret, isActive, children }) => {
    
    const toggleHandler = () => {
        //setActive(!active);
        action(!isActive);
    }

    // useEffect(() => {
    //     if (!didMount.current) {
    //         return didMount.current = true;
    //     }
    //     action(active);
    // }, [active]);

    const renderNavItem = () => {
        switch (type) {
            case 'toggler':
                return (
                    <button onClick={toggleHandler} className={[styles.button, isActive && styles.active].join(" ")} title={title}>
                        <span>{children}</span>{showCaret && <Icon path={mdiChevronDown} size={1} className={styles.chevron} />}
                    </button>
                );
            case 'link':
            default:
                return (
                    <NavLink to={to} title={title} className={[styles.a, styles.active].join(" ")} >{children}</NavLink>
                );
        }
    }

    return (
        renderNavItem()
    )
}

export default NavItem


