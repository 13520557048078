import React from 'react'
import styles from './styles.module.scss'
import AppFooterBrand from '../AppFooterBrand'
import SocialIcon from '../Framework/SocialIcon'

import { mdiFacebook } from '@mdi/js'
import { mdiInstagram } from '@mdi/js'
import { mdiTwitter } from '@mdi/js'
import { tiktok } from '../../assets/icons/tiktok'
import { Link, useLocation } from 'react-router-dom'
import { policies } from '../../data/policies'


const AppFooter = () => {

  const location = useLocation();



  return (
    <footer className={styles.footer}>
      <div className={styles.layout}>
        <section className={styles.mark}>
          <AppFooterBrand className={styles.appFooterBrand}></AppFooterBrand>
          <ul className={styles.social}>

            <li>
              <SocialIcon path={mdiFacebook}
                link=""
                title="" />
            </li>
            <li>
              <SocialIcon path={mdiInstagram}
                link=""
                title="" />
            </li>
            <li>
              <SocialIcon path={mdiTwitter}
                link=""
                title="" />
            </li>
            <li>
              <SocialIcon path={tiktok}
                link=""
                title="" />
            </li>
          </ul>
        </section>

        <section className={styles.legalPolicies}>
        </section>
        <section className={styles.contact}>
          <h5>Contact Us</h5>
          <article>
            <ul>
              <li>Email:</li>
              <li><a href="mailto:contactus@lamansys.com.ar">contactus@lamansys.com.ar</a></li>
              <li>Call or Text:</li>
              <li><a href="tel:"></a></li>
            </ul>
          </article>


        </section>

      </div>

    </footer>
  )
}

export default AppFooter
