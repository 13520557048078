import React from 'react'
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { Link, useNavigate } from "react-router-dom"

import { setUser, setUserEmail, setPreOrderEmail } from "../../../redux/actions/userAction";
import { setLocalData } from '../../../util/helper';
import { deleteAllFromCart } from "../../../redux/actions/cartActions";

import Icon from '@mdi/react'
import { mdiLockOutline } from '@mdi/js'

import Button from '../../Button'

import styles from './styles.module.scss'

//TODO: get text from translation file

const UserMenu = ({ userDetails, setUser, deleteAllFromCart, setUserEmail, setPreOrderEmail, onNavigate }) => {

    //console.log('UserMenu 👉🏻 ', userDetails);
    const navigate = useNavigate()

    const logout = () => {
        setUser('')
        setUserEmail('')
        setPreOrderEmail('')
        setLocalData('token', '')
        deleteAllFromCart() // Why we need to delete all cart items on logout?
        navigate(0)
    }

    const IdentifiedUserMenu = () => {
        return (
            <div className={styles.menuContainer}>
                <header className={styles.userHeader}>
                    <h1>Hi <strong>{userDetails?.firstName} {userDetails?.lastName}</strong>!</h1>
                    <p>{userDetails?.userName}</p>
                    <Button size="s" variant={"secondary"} action={logout} leftIcon={<Icon path={mdiLockOutline} />}>
                        Logout
                    </Button>
                </header>
                <ul className={styles.options}>
                    <li>
                        <Link to="/my-account" onClick={()=> onNavigate()}>
                            My Account
                        </Link>
                    </li>
                    <li>
                        <Link to="/update-password" onClick={()=> onNavigate()}>
                            Change your Password
                        </Link>
                    </li>
                    <li>
                        <Link to="/recent-order" onClick={()=> onNavigate()}>
                            Recent Orders
                        </Link>
                    </li>
                    <li>

                    </li>
                </ul>
            </div>
        )
    }

    const NotIdentifiedUserMenu = () => {
        //This component was almost fully written by Github Copilot 🤖
        return (
            <div className={styles.menuContainer}>
                <header className={styles.userHeader}>
                    <h1>Hi <strong>Guest</strong>!</h1>
                    <p>Sign in to see your orders</p>
                    <Button size="s" variant={"secondary"} action={() => navigate('/login')} leftIcon={<Icon path={mdiLockOutline} />}>
                        Login
                    </Button>
                </header>
            </div>
        )
    }

    return (
        userDetails ? <IdentifiedUserMenu /> : <NotIdentifiedUserMenu />
    )
}


const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: (data) => {
            dispatch(setUser(data));
        },
        setUserEmail: (data) => {
            dispatch(setUserEmail(data));
        },
        setPreOrderEmail: (data) => {
            dispatch(setPreOrderEmail(data));
        },
        deleteAllFromCart: () => {
            dispatch(deleteAllFromCart())
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(UserMenu));
