import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import styles from './styles.module.scss'

const SelectAutoSuggest = ({ placeholder, allSuggestions, onSelectSuggestion = () => {}, inputClassName = null, lastUpdated }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSelectSuggestion(value);
            onSuggestionsFetchRequested({ value });
        }
    };

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const renderSuggestion = ({ description, image }) => (
        <div className={styles.suggestContainer}>
            <div className={styles.suggestImageContainer}>
                <img
                    style={{ height: 24, maxWidth: '100%', marginRight: 8, objectFit: 'cover' }}
                    className="img-fluid"
                    src={image?.imageUrl}
                    alt={image?.imageName}
                    />
            </div>
            <span className={styles.autosuggest}>
                {description.name}
            </span>
        </div>
    );

    const inputProps = {
        placeholder,
        value,
        onChange: onChange,
        onKeyDown: onKeyDown,
        className: inputClassName,
    };

    const getSuggestionValue = ({ description }) => {
        onSelectSuggestion(description.name);
        return description.name;
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        const filteredSuggestions = allSuggestions.filter(({ description }) =>
            description.name?.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5);
        setSuggestions(filteredSuggestions);
    };

    useEffect(() => {
        if (value !== '') {
            onSelectSuggestion(value);
        }
    }, [lastUpdated])

    return <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={() => setSuggestions([])}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
}

export default SelectAutoSuggest;
