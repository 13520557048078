import { SET_CONTENT_ID, GET_CONTENT } from "../actions/contentAction";

const initState = {
    contentId: '',
    allContent: [],
    called: false,
};

const contentReducer = (state = initState, action) => {
    if (action.type === SET_CONTENT_ID) {
        return {
            ...state,
            contentId: action.payload
        };
    }

    if (action.type === GET_CONTENT) {
        return {
            ...state,
            allContent: action.payload.items,
            called: true,
        }
    }

    return state;
};

export default contentReducer;
