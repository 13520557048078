import React from 'react'
import { Link } from "react-router-dom"
import styles from './styles.module.scss'

import logo from '../../assets/images/lamansysLogo.png'
import icon from '../../assets/images/lamansysIcon.png'

const HeaderBrand = () => {
    return (
        <Link to="/" className={styles.HeaderBrand}>
            <img src={icon} alt="Lamansys Icon" className={`${styles.logo} ${styles.onlyMobile}`} />
            <img src={logo} alt="Lamansys Logo" className={`${styles.logo} ${styles.onlyDesktop}`} />
        </Link>
    )
}

export default HeaderBrand
